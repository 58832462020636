<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import Vue from 'vue';
import BootstrapVue, {
    BIconPlus,
    BIconPencilSquare,
    BIconTrash,
    BIconCreditCard,
    BIconFileEarmarkText,
    BIconChevronCompactRight,
    BIconClockFill
} from 'bootstrap-vue';
import http from '@/http';
import store, { actions } from '@/store';
import router, { routes } from '@/router';
import i18n, { setLang } from '@/localization';

Vue.use(BootstrapVue);
Vue.use(store);

Vue.component('BIconPlus', BIconPlus);
Vue.component('BIconPencilSquare', BIconPencilSquare);
Vue.component('BIconTrash', BIconTrash);
Vue.component('BIconCreditCard', BIconCreditCard);
Vue.component('BIconFileEarmarkText', BIconFileEarmarkText);
Vue.component('BIconChevronCompactRight', BIconChevronCompactRight);
Vue.component('BIconClockFill', BIconClockFill);

Vue.mixin({
    methods: {
        delay(time) {
            return new Promise(resolve => setTimeout(resolve, time));
        }
    }
});

export default {
    name: 'App',
    data() {
        return {
            loaded: false
        };
    },
    props: {
        apiBasePath: {
            type: String,
            required: true
        },
        accessToken: {
            type: String,
            required: true
        },
        lang: {
            type: String,
            required: true
        },
        impersonateEmail: {
            type: String,
            required: false
        },
        impersonatePersonKey: {
            type: String,
            required: false
        }
    },
    i18n,
    router,
    store,
    watch: {
        apiBasePath: {
            immediate: true,
            handler(path) {
                this.loadData();
            }
        },
        accessToken: {
            immediate: true,
            handler(path) {
                this.loadData();
            }
        },
        lang: {
            immediate: true,
            handler(path) {
                setLang(path);
            }
        },
        impersonateEmail: {
            immediate: true,
            handler(path) {
                if (this.impersonateEmail) {
                    http.setImpersonatePersonKey(this.impersonateEmail);
                    this.loadData();
                }
            }
        },
        impersonatePersonKey: {
            immediate: true,
            handler(path) {
                if (this.impersonatePersonKey) {
                    http.setImpersonatePersonKey(this.impersonateEmail);
                    this.loadData();
                }
            }
        }
    },
    methods: {
        loadData() {
            if (this.apiBasePath && this.accessToken) {
                this.loaded = true;

                http.setApiPath(this.apiBasePath);
                http.setAccessToken(this.accessToken);
                this.$store.commit('clearErrors');
                this.$store.dispatch(actions.CLEAR_VEHICLES).then(() => this.$store.dispatch(actions.LOAD_VEHICLES));
                this.$store.dispatch(actions.LOAD_AGREEMENTS);
                router.push(routes.MY_VEHICLES.path).catch(() => {});
            }
        }
    },
    mounted() {
        setLang(this.lang || 'en');
    }
};
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';
@import 'style/variables.scss';
@import 'style/style.scss';

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
