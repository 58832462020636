export const nb = {
    MyVehiclesPageTitle: 'MINE KJØRETØY',
    AddNewBtn: 'Ny',
    NewVehicle: 'NYTT KJØRETØY',
    Vehicle: 'KJØRETØY - ',
    EmptyListTitleLine1: 'Du kan registrere opp til 3  kjøretøy.',
    EmptyListTitleLine2: 'Kjøretøyene kan være dine eller dine gjesters.',
    EmptyListTitleLine3: 'Har  du parkeringsavtale må du velge hvilket kjøretøy som skal knyttes til avtalen.',
    EmptyListTitleLine4: 'Uavhengig av parkering, må du registrere hvilket/hvilke kjøretøy du ankommer med for å komme inn porten.',
    LicensePlateEdit: 'Skiltnummer',
    LicensePlateValidationText: 'Nummer på nummerplaten kan ikke være tomt og består kun av bokstaver og tall.',
    LicensePlatePlaceholderText: 'Skriv registreringsnummer',
    AliasEdit: 'Navn på kjøretøy',
    AliasValidationText: 'Alias kan ikke være tomt.',
    AliasPlaceholderText: 'Skriv et navn på kjøretøy',
    BackText: 'Tilbake',
    SaveText: 'Lagre',
    ParkingAgreementsListTitle: 'Parkeringsavtale',
    ParkingAgreementsEdit: 'Knyttes til parkeringsavtale:',
    DealText: 'Avtale ',
    TimeScopedEdit: 'Dette er en leiebil',
    ParkingAgreementLicensePlate: 'Parkeringsavtale',
    ParkingAgreementLicensePlateEmpty: 'Velg kjøretøy',
    NoneParkingAgreement: 'Ingen',
    ParkingAgreementDescriptionLineLongTerm: 'Din avtale gir deg rett til å parkere kostnadsfritt på Oslofjord sine parkeringsarealer.',
    ParkingAgreementDescriptionLine1Reservation: 'Din avtale gir deg rett til å parkere kostnadsfritt på Oslofjord sine parkeringsarealer når du har en aktiv reservasjon på suiteleieavtalen.',
    ParkingAgreementDescriptionLine1Sommer: 'Din avtale gir deg rett til å parkere kostnadsfritt på Oslofjord sine parkeringsarealer når du har en aktiv reservasjon på sommeravtalen.',
    ParkingAgreementDescriptionLine1Parkering: 'Din avtale gir deg rett til å parkere kostnadsfritt på parkeringsområde tildelt av Brunstadstiftelsen.',
    ParkingAgreementDescriptionLine2: 'Avtalen er gyldig for ett kjøretøy av gangen.',
    ParkingAgreementDescriptionLine3: 'Du er ansvarlig for at riktig kjøretøy er knyttet til avtalen til enhver tid.',
    RemoveVehicleQuestion: 'Er du sikker på at du vil slette kjøretøyet?',
    Yes: 'Ja',
    No: 'Nei',
    ErrorMessage: 'For øyeblikket kan du ikke legge til ny eller endre informasjon om kjøretøyet.',
    ResRequiredInfo: 'Krever reservasjon av suite',
    LinkedLicensePlate: 'Knyttet til:'
};

export default nb;
