<template>
    <span>
        <svg id="Capa_1" enable-background="new 0 0 458.706 458.706" height="26" viewBox="0 0 458.706 458.706" width="26" xmlns="http://www.w3.org/2000/svg"><path d="m229.353 163.824h-32.765v65.529h32.765c18.062 0 32.765-14.703 32.765-32.765s-14.703-32.764-32.765-32.764z"/><path d="m425.941 0h-393.176c-18.094 0-32.765 14.671-32.765 32.765v393.176c0 18.094 14.671 32.765 32.765 32.765h393.176c18.094 0 32.765-14.671 32.765-32.765v-393.176c0-18.094-14.671-32.765-32.765-32.765zm-196.588 294.882h-32.765v65.529h-65.529v-262.117h98.294c54.203 0 98.294 44.091 98.294 98.294s-44.091 98.294-98.294 98.294z"/>
        </svg>
    </span>
</template>

<script>
export default {
    name: 'IconParking'
};
</script>

<style scoped>

</style>
