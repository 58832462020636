<template>
    <div>
        <span class="page-title">{{ $t('ParkingAgreementsListTitle') }}</span>
        <router-link v-bind:to="{ name: 'MyVehicles' }">
            <b-button size="lg" class="float-right m-2 mr-5 action-btn center-it">
                <span>{{ $t('BackText') }}</span>
            </b-button>
        </router-link>
        <div class="page-separator"></div>
        <b-card>
            <IconParking class="float-left mt-3 ml-4 mr-4"/>
            <h3 class="mt-4">{{ $t('ParkingAgreementLicensePlate') }}<strong>{{ agreementNumber }}</strong></h3>
            <div class="ml-4 mt-4">
                <p class="m-2 mt-4">{{ getValidDescription() }}</p>
                <p class="m-2 mt-4">{{ $t('ParkingAgreementDescriptionLine2') }}</p>
                <p class="m-2 mt-4">{{ $t('ParkingAgreementDescriptionLine3') }}</p>
            </div>
        </b-card>
    </div>
</template>

<script>
import IconParking from '@/components/IconParking';
import ParkingAgreement from '@/store/ParkingAgreement';

export default {
    name: 'ParkingAgreementDetails',
    components: { IconParking },
    data() {
        return {
            agreement: ParkingAgreement.EmptyInstance()
        };
    },
    computed: {
        agreementNumber() {
            if (this.$store.getters.selectedAgreement.title) {
                return ': ' + this.$store.getters.selectedAgreement.title;
            } else {
                return '';
            }
        }
    },
    mounted() {
        if (this.$store.getters.selectedAgreement) {
            this.agreement = this.$store.getters.selectedAgreement;
        }
    },
    methods: {
        getValidDescription() {
            if (this.agreement.type === 'ReservationParking') {
                if (this.agreement.code === 'Sommeravtale/30') {
                    return this.$i18n.t('ParkingAgreementDescriptionLine1Sommer');
                } else {
                    return this.$i18n.t('ParkingAgreementDescriptionLine1Reservation');
                }
            } else if (this.agreement.type === 'LongTermParking') {
                if (this.agreement.code === 'Parkering') {
                    return this.$i18n.t('ParkingAgreementDescriptionLine1Parkering');
                } else {
                    return this.$i18n.t('ParkingAgreementDescriptionLineLongTerm');
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "src/style/style";

</style>
