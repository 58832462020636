import Axios from 'axios';

class Http {
    get requester() {
        this.axios = Axios.create({
            baseURL: this.apiPath,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${this.accessToken}`,
                'X-Impersonate-PersonKey': `${this.impersonatePersonKey}`
            }
        });   
        return this.axios;
    }

    setApiPath(path) {
        this.apiPath = path;
    }

    setAccessToken(token) {
        this.accessToken = token;
    }

    setImpersonatePersonKey(impersonatePersonKey) {
        if (impersonatePersonKey) {
            this.impersonatePersonKey = impersonatePersonKey;
        }
    }
}

const http = new Http();
export default http;
