import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enTranslations from './en';
import nbTranslations from './nb';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en'
});

export function setLang(langName) {
    let translations = enTranslations;
    if (langName === 'nb' || langName === 'no') {
        translations = nbTranslations;
        langName = 'no';
    }
    i18n.setLocaleMessage(langName, translations);
    i18n.locale = langName;
};

export default i18n;
